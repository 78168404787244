import loadable from '@loadable/component';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { closeDialog } from '../../actions/ui';
import NewMemoriesDialog from '../../features/Memory/Components/NewMemoriesDialog';
import PaidFeaturePopupDialog from '../../features/PaidFeaturePopup/PaidFeaturePopupDialog';
import { Dialogs } from '../../types/enums';
import isKeyCombination from '../../utils/isKeyCombination';
import useNonemptyValue from '../../utils/useNonemptyValue';
import DialogContext from '../DialogLayout/DialogContext';
import { APPEAR_TIMEOUT } from '../DialogLayout/legacy/DialogLayout';
import AgeGateDialog from './AgeGateDialog';
import { AgeGateRange } from './AgeGateDialog/AgeGateRange';
import AskChangeStatusDialog from './AskChangeStatusDialog';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';
import ClaimItemDialog from './ClaimItemDialog';
import ConfirmationDialog from './ConfirmationDialog';
import { DialogBack } from './DialogBack';
import DropMissionDialog from './DropMissionDialog';
import HelpInCrisisDialog from './HelpInCrisisDialog';
import HintDialog from './HintDialog';
import NavigationPopupDialog from './NavigationPopupDialog';
import PurchaseStoreItemDialog from './PurchaseStoreItemDialog';
import ReactionDialog from './ReactionDialog';
import ReboardingAddPronounDialog from './ReboardingAddPronounDialog';
import VoiceCallConfirmationDialog from './VoiceCallConfirmationDialog';
import VoiceCallFeedbackDialog from './VoiceCallFeedbackDialog';
import { useActiveDialog } from './hooks';

const SaleScreenDialog = loadable(
  () => import(/* webpackChunkName: "SaleScreenDialog" */ './SaleScreenDialog'),
);

const DailyRewardDialogLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: "DailyRewardDialog" */ '../../features/DailyRewards/DailyRewardDialog'
    ),
);

export type DialogHostProps = {
  className?: string;
};

type Visibility = 'hidden' | 'visible' | 'hiding';

const DIALOGS: [Dialogs, React.ComponentType<any>][] = [
  [Dialogs.DropMission, DropMissionDialog],
  [Dialogs.Confirmation, ConfirmationDialog],
  [Dialogs.Hint, HintDialog],
  [Dialogs.ReboardingAddPronoun, ReboardingAddPronounDialog],
  [Dialogs.Reaction, ReactionDialog],
  [Dialogs.NavigationPopup, NavigationPopupDialog],
  [Dialogs.CancelSubscription, CancelSubscriptionDialog],
  [Dialogs.DailyReward, DailyRewardDialogLoadable],
  [Dialogs.PurchaseStoreItem, PurchaseStoreItemDialog],
  [Dialogs.VoiceCallFeedback, VoiceCallFeedbackDialog],
  [Dialogs.VoiceCallConfirmation, VoiceCallConfirmationDialog],
  [Dialogs.SaleScreen, SaleScreenDialog],
  [Dialogs.AskChangeStatus, AskChangeStatusDialog],
  [Dialogs.ClaimItem, ClaimItemDialog],
  [Dialogs.PaidFeaturePopup, PaidFeaturePopupDialog],
  [Dialogs.AgeGate, AgeGateDialog],
  [Dialogs.AgeRange, AgeGateRange],
  [Dialogs.HelpInCrisis, HelpInCrisisDialog],
  [Dialogs.NewMemories, NewMemoriesDialog],
];

export function registerDialog(
  dialogType: Dialogs,
  Dialog: React.ComponentType<any>,
) {
  DIALOGS.push([dialogType, Dialog]);
}

const STRICT_CLOSE_DIALOGS = [
  Dialogs.ReboardingAddPronoun,
  Dialogs.SaleScreen,
  Dialogs.DailyReward,
  Dialogs.AgeGate,
  Dialogs.AgeRange,
  Dialogs.NewMemories,
];

function DialogHost(props: DialogHostProps) {
  const dispatch = useDispatch();

  const activeDialog = useActiveDialog();

  const claim_status = useSelector(
    (state) => state.store.persist.loginReward?.claim_status,
  );
  const isRewardAvailable = claim_status === 'Available';

  const { className } = props;

  const [visibility, setVisibility] = React.useState<Visibility>('hidden');
  // store currently appearing/visible dialog and hiding dialog to make sure it's visible during animations
  const [visibleDialog, setVisibleDialog] = React.useState<Dialogs | null>(
    null,
  );
  const [hidingDialog, setHidingDialog] = React.useState<Dialogs | null>(null);

  React.useEffect(() => {
    if (activeDialog && visibleDialog !== activeDialog.type) {
      if (visibleDialog) {
        setHidingDialog(visibleDialog);
      }
      setVisibleDialog(activeDialog.type);
    }

    if (!activeDialog && visibility === 'hidden' && visibleDialog !== null) {
      setHidingDialog(null);
      setVisibleDialog(null);
    }
  }, [activeDialog, visibleDialog, visibility]);

  React.useEffect(() => {
    if (hidingDialog) {
      const hideTimeout = setTimeout(
        () => setHidingDialog(null),
        APPEAR_TIMEOUT,
      );

      return () => clearTimeout(hideTimeout);
    }

    return undefined;
  }, [hidingDialog, setHidingDialog]);

  React.useEffect(() => {
    let visibleTimeout;
    switch (visibility) {
      case 'hidden':
        // if there is a dialog to show, go to visible state
        if (activeDialog) {
          setVisibility('visible');
        }
        break;
      case 'visible':
        // when there's no active dialog, go to hiding state
        if (!activeDialog) {
          setVisibility('hiding');
        }
        break;
      case 'hiding':
        if (!activeDialog) {
          // wait for APPEAR_TIMEOUT ms before hiding root for real, so there's a time for hiding transition
          visibleTimeout = setTimeout(() => {
            if (visibility === 'hiding') {
              setVisibility('hidden');
            }
          }, APPEAR_TIMEOUT);
        } else {
          setVisibility('visible');
        }
        break;
    }

    return () => {
      clearTimeout(visibleTimeout);
    };
  }, [activeDialog, visibility]);

  const onClose = () => {
    if (activeDialog) dispatch(closeDialog(activeDialog.type));
  };

  const isRendered = (type: Dialogs) =>
    visibleDialog === type || hidingDialog === type;

  const isDialogClosable =
    (activeDialog && !STRICT_CLOSE_DIALOGS.includes(activeDialog.type)) ||
    (activeDialog &&
      activeDialog.type === Dialogs.DailyReward &&
      !isRewardAvailable);

  const handleScrollClick = (e) => {
    // we can't use ref here since focus-trap-react ovverrides it,
    // so we just check div id
    const target = e.target as HTMLDivElement;
    if (target.id === 'dialog-scroll' && isDialogClosable) {
      dispatch(closeDialog(activeDialog.type));
    }
  };

  return visibility === 'hidden' ? null : ( // unmount all dialogs in hidden state
    <DialogHostRoot
      data-remainpopup
      className={className}
      onKeyDown={(e) => {
        if (isKeyCombination(e, 'Escape') && isDialogClosable) {
          // we only want to close current active dialog
          e.stopPropagation();
          dispatch(closeDialog(activeDialog.type));
        }
      }}
    >
      <DialogBack
        id="dialog-back"
        $isActive={visibility !== 'hiding'}
        $withoutBlur={
          activeDialog?.type === Dialogs.Confirmation &&
          !!activeDialog?.disableBackdropBlur
        }
        onClick={(e) => {
          if (isDialogClosable) {
            dispatch(closeDialog(activeDialog.type));
          }
        }}
      />
      {DIALOGS.map(([dialogType, Dialog]) => {
        const isActive = dialogType === activeDialog?.type;
        const dialogParams = isActive ? activeDialog : null;

        return isRendered(dialogType) ? (
          <DialogContext.Provider
            key={dialogType}
            value={{
              isActive,
              onScrollClick: handleScrollClick,
            }}
          >
            <MemoizedParamsDialog
              component={Dialog}
              onClose={onClose}
              dialogParams={dialogParams}
            />
          </DialogContext.Provider>
        ) : null;
      })}
    </DialogHostRoot>
  );
}

function MemoizedParamsDialog({ component: Dialog, onClose, dialogParams }) {
  const memoizedParams = useNonemptyValue(dialogParams);

  return <Dialog onClose={onClose} {...memoizedParams} />;
}

export default React.memo(DialogHost);

/*

   +----------------------------------------------------+
   |                                                    |
   |   DialogHostRoot - centers content vertically      |
   |                                                    |
   +----------------------------------------------------+
   ||                                                 |-|
   ||                                                 |-|
   ||  DialogScroll - max height is screen height,    |-|
   ||  content has overflow scrolling;                |-|
   ||  centers content horizontally                   |-|
   ||                                                 |-|
   ||  (it should be full-width, so that if           |-|
   ||  the content is overflowing, we could scroll it |-|
   ||  from any point)                                |-|
   ||                                                 |-|
   ||  (MOVED TO DialogLayout)                        |-|
   ||                                                 |-|
   ||                                                 |-|
   +----------------------------------------------------+
   |                                                    |
   |                                                    |
   |                                                    |
   +----------------------------------------------------+

*/

const DialogHostRoot = styled.div`
  position: absolute;
  width: 100vw;
  height: 100svh;
  display: flex;
  align-items: center;
`;
