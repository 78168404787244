import pkg from '../../package.json';
import { FeatureFlags } from '../core/featureFlags';
import {
  GenderValues,
  Genders,
  Pronouns,
  PronounsValues,
} from '../types/enums';
import { RelationshipStatusId } from '../types/models';

/**
 * Disable PayPal region speficic funding/payment options
 * @see https://developer.paypal.com/sdk/js/configuration/#link-disablefunding
 */
export const PAYPAL_DISABLED_FUNDING_OPTIONS =
  'card,credit,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo';

export const WS_VERSION = 'v17';

export const APP_VERSION = pkg.version;
export const RELEASE_NAME = `${pkg.name}@${APP_VERSION}`;

// x.x.x for production, branch-<branch_name> for dev builds
export const BUILD_VERSION = process.env.REACT_APP_VERSION || '';

export const ROOM_DISABLED = false;

export const ROMANTIC_RELATIONSHIP_STATUS_ID: RelationshipStatusId = 'romantic';
export const DEFAULT_RELATIONSHIP_STATUS_ID = 'friend';

export const CAPABILITIES = [
  'widget.multiselect',
  'widget.scale',
  'widget.titled_text_field',
  'widget.new_onboarding',
  'widget.app_navigation',
  'widget.mission_recommendation',
  'journey2.daily_mission_activity',
  'journey2.replika_phrases',
  'new_payment_subscriptions',
  'navigation.relationship_settings',
  'avatar',
  'diaries.images',
  'wallet',
  'store.dialog_items',
  'chat_suggestions',
  '3d_customization',
  '3d_customization_v2',
  '3d_customization_v3',
  'store_customization',
  'blurred_messages',
  'item_daily_reward',
  'romantic_photos',
  'voice_messages',
  'stable_diffusion2',
  'subscription_request',
  'sale_screen',
  'multiple_bot_selfies',
  'hot_photos_v2',
  'store_age_and_body_type',
  'diary_generation_by_session',
  'store_voice',
  'coaching_prompts',
  'voice_sample_streaming',
  'pets_in_memory',
  'quests',
  'ai_art_ui',
  'name_moderation',
  'special_offer_screen',
];

if (FeatureFlags.statementsAboutReplika) {
  CAPABILITIES.push('memory_sar_temporary');
}

export const DEV_ONLY_CAPABILITIES: string[] = [];

// iPad mini width in portrait mode
export const DIALOG_MOBILE_WIDTH_THRESHOLD = 768;
export const DIALOG_TABLET_WIDTH_THRESHOLD = 1024;

// iPad mini width in portrait mode
export const CHAT_MOBILE_WIDTH_THRESHOLD = 768;

// FIXME: use laptop breakpoint
export const RIGHT_PANEL_WIDTH_THRESHOLD = 1280;

export const GDPR_VERSION = 1;

export const PRONOUNS_OPTS = [
  { label: Pronouns.SheHer, value: PronounsValues.She },
  { label: Pronouns.TheyThem, value: PronounsValues.They },
  { label: Pronouns.HeHim, value: PronounsValues.He },
];

export const GENDER_OPTS = [
  { label: Genders.Female, value: GenderValues.Female },
  { label: Genders.NonBinary, value: GenderValues.NonBinary },
  { label: Genders.Male, value: GenderValues.Male },
];

export const DEFAULT_AVATAR_ID = '5f4a5e85fa15980007ed7000';

export const IMAGE_MAX_PIXEL_AREA = 1200000;
