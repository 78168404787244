import { getDefaultStore } from 'jotai';
import { AvatarPhotoData } from '../core/AvatarUnity/types';
import { avatarEmotionAtom } from '../core/atoms';
import * as A from '../types/actions';
import { ActionTypes, AsyncActionTypes, PronounsValues } from '../types/enums';
import {
  Message,
  UnityBinariesResponse,
  UnityWebEngine,
} from '../types/models';
import { DA } from '../types/redux';
import { RoomSettings, RootState } from '../types/states';
import { apiAction } from '../utils/asyncAction';
import fetchOptions from '../utils/fetchOptions';
import getUnityBundleVersion from '../utils/getUnityBundleVersion';
import toQueryString from '../utils/toQueryString';
import { API_BASE_URL } from '../utils/uri';
import { setAvatarMode } from './profile';

export const getAvatars =
  (genderOrder?: PronounsValues): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetAvatars>(
      AsyncActionTypes.GetAvatars,
      dispatch,
      { genderOrder },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/3d_avatar_v2/models?${toQueryString({
              legacy_only: false,
              unity_bundle_version: getUnityBundleVersion(),
              store_customization: true,
              gender_order: genderOrder,
            })}`,
            fetchOpts,
          ),
      },
    );
  };

export const getAvatarMeta =
  (avatarId: string): DA<object> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetAvatarMeta>(
      AsyncActionTypes.GetAvatarMeta,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/3d_avatar_v2/meta_json/${avatarId}/web`,
            fetchOpts,
          ),
        onError: () => {
          dispatch(setAvatarMode('disabledOnError'));
        },
      },
    );
  };

export const getUnityBinaries =
  (version = getUnityBundleVersion()): DA<UnityBinariesResponse> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetUnityBinaries>(
      AsyncActionTypes.GetUnityBinaries,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/3d_avatar_v2/binaries/web?version=${version}`,
            fetchOpts,
          ),
        onError: () => {
          dispatch(setAvatarMode('disabledOnError'));
        },
      },
    );
  };

export const setChooseAvatarScreenShown = (): A.SetChooseAvatarScreenShown => {
  return {
    type: ActionTypes.SetChooseAvatarScreenShown,
  };
};

export type AvatarStatus =
  | 'idle'
  | 'loading'
  | 'updating'
  | 'hidden-update'
  | 'ready'
  | 'error';

export const setAvatarStatus = (status: AvatarStatus): A.SetAvatarStatus => {
  return {
    type: ActionTypes.SetAvatarStatus,
    status,
  };
};

export const setLastUnityVersion = (version: number): A.SetLastUnityVersion => {
  return {
    type: ActionTypes.SetLastUnityVersion,
    version,
  };
};

export const getUnityWebEngine =
  (version: number): DA<UnityWebEngine> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetUnityWebEngine>(
      AsyncActionTypes.GetUnityWebEngine,
      dispatch,
      { version },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/3d_avatar_v2/unity_web_engine?version=${version}`,
            fetchOpts,
          ),
        onError: () => {
          dispatch(setAvatarMode('disabledOnError'));
        },
      },
    );
  };

export const setRoomSettings = (
  settings: Partial<RoomSettings>,
): A.SetRoomSettings => {
  return {
    type: ActionTypes.SetRoomSettings,
    settings,
  };
};

export const setAvatarViewMode = (
  viewMode: 'main' | 'subscription',
): A.SetAvatarViewMode => {
  return {
    type: ActionTypes.SetAvatarViewMode,
    viewMode,
  };
};

export const sendAvatarPhoto =
  (imageFile: File, params: AvatarPhotoData, onSuccess?: () => void) =>
  async (_, getState: () => RootState) => {
    const { profile } = getState();
    const { id, age, body_type } = profile.persist.bot?.avatar_v2 || {};

    if (!id) return;

    const apiContext =
      params.type === 'hot' ? 'upload_romantic_photo' : 'upload_with_pose';

    const formData = new FormData();

    formData.set('image', imageFile);
    formData.set(
      'image_info',
      JSON.stringify({
        avatar_id: id,
        active_variations: params.items,
        pose: params.id,
        age: age || 0,
        body_type,
      }),
    );

    return fetch(
      `${API_BASE_URL}/personal_bot/images/actions/${apiContext}`,
      fetchOptions(getState(), 'POST', formData),
    ).then(() => {
      onSuccess?.();
    });
  };

export const setAvatarMessageEmotion = (message: Message) => {
  const messageEmotion = message.avatarEmotion?.name;
  const isRobotMessage = message.meta.nature === 'Robot';
  if (isRobotMessage && messageEmotion) {
    const store = getDefaultStore();
    store.set(avatarEmotionAtom, messageEmotion);
  }
};
